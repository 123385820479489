import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
    ProSidebar,
    Menu,
    MenuItem,
    SidebarHeader,
    SidebarFooter,
    SidebarContent,
} from 'react-pro-sidebar';
//import { FaTachometerAlt, FaGem, FaList, FaGithub, FaRegLaughWink, FaHeart, FaTimes, FaFile, FaBars, FaCross, FaListAlt } from 'react-icons/fa';
import { FaList, FaFile, FaListAlt, FaGrinTongueWink, FaPrint } from 'react-icons/fa';

import sidebarBg from '../../assets/bg1.jpg';
import UserSidebar from '../../components/UserSidebar';
import ComponentWrapper from './ComponentWrapper';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { selectCurrent } from '../../Selectors';
import Left from './Left';
import Right from './Right';
import Wrapper from './Wrapper';

const Aside = ({ image, collapsed, rtl, toggled, handleToggleSidebar, handleCollapsedChange }) => {
    const intl = useIntl();
    const history = useHistory();
    const { user, isAuthenticated } = useAuth0();

    //Selectors    
    const current = useSelector(selectCurrent);

    function handleA3OverviewMenuClicked() {
        history.push("/A3Overview");
    }

    function handleMenuClickedPrintPreview() {
        history.push("/printA3");
    }

    function handleMenuClickedPrintAlvance() {
        history.push("/printA3Alvance");
    }

    function handleMenuClickedPrintLean() {
        history.push("/printA3Lean");
    }

    function handleMenuClickedPrintDSM() {
        history.push("/printA3DSM");
    }

    function handleCurrentA3MenuClicked() {
        if (current && current.a3Id)
            history.push("/A3/");
    }

    function handleMenuClickedTodo() {
        history.push("/todo");
    }

    return (
        <ComponentWrapper>
            <ProSidebar
                image={image ? sidebarBg : false}
                rtl={rtl}
                collapsed={collapsed}
                toggled={toggled}
                breakPoint="xl"
                onToggle={handleToggleSidebar}
            >
                <SidebarHeader>
                    <Wrapper>
                        <Left style={{}}><img src="./ThinkA3white.png" width='120px' /></Left>
                        <Right></Right>
                    </Wrapper>
                    {/*
                <div
          style={{
            padding: '24px',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: 18,
            letterSpacing: '1px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {intl.formatMessage({ id: 'sidebarTitle' })}
        </div>
        */}

                    {/*
        <Switch
          height={16}
          width={30}
          checkedIcon={false}
          uncheckedIcon={false}
          onChange={handleCollapsedChange}
          checked={collapsed}
          onColor="#219de9"
          offColor="#bbbbbb"
        />        
        */}
                    <div className="sidebar-wrapper">
                        <UserSidebar />
                    </div>
                </SidebarHeader>

                {
                    !isAuthenticated ? null :
                        <SidebarContent>
                            <Menu iconShape="circle">
                                <MenuItem>Overview</MenuItem>
                                <MenuItem
                                    onClick={() => handleA3OverviewMenuClicked()}
                                    icon={<FaList />}>
                                    All A3s
                                </MenuItem>
                            </Menu>
                            {current && current.a3Id && isAuthenticated && (user.email !== "mark.volders@thinka3.com" && user.email !== "tom.macquoy@thinka3.com" && user.email !== "christ.vrouwe@dsm.com" && user.email !== "athina.debaetselier@alvancegroup.com") ?
                                <Menu iconShape="circle">
                                    <MenuItem>Current</MenuItem>
                                    <MenuItem
                                        onClick={() => handleCurrentA3MenuClicked()}
                                        icon={<FaFile />}>
                                        A3
                                   </MenuItem>
                                    <MenuItem
                                        onClick={() => handleMenuClickedPrintPreview()}
                                        icon={<FaPrint />}>
                                        Print preview
                                    </MenuItem>
                                </Menu>
                                : null
                            }
                            {current && current.a3Id && isAuthenticated && (user.email === "mark.volders@thinka3.com" || user.email === "tom.macquoy@thinka3.com") ?
                                <Menu iconShape="circle">
                                    <MenuItem>Current</MenuItem>
                                    <MenuItem
                                        onClick={() => handleCurrentA3MenuClicked()}
                                        icon={<FaFile />}>
                                        A3
                                   </MenuItem>
                                    <MenuItem
                                        onClick={() => handleMenuClickedPrintPreview()}
                                        icon={<FaPrint />}>
                                        Print preview
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => handleMenuClickedPrintAlvance()}
                                        icon={<FaPrint />}>
                                        Print preview Alvance
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => handleMenuClickedPrintDSM()}
                                        icon={<FaPrint />}>
                                        Print preview DSM
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => handleMenuClickedPrintLean()}
                                        icon={<FaPrint />}>
                                        Print preview Lean
                                    </MenuItem>
                                </Menu>
                                : null
                            }
                            {current && current.a3Id && isAuthenticated && (user.email === "christ.vrouwe@dsm.com") ?
                                <Menu iconShape="circle">
                                    <MenuItem>Current</MenuItem>
                                    <MenuItem
                                        onClick={() => handleCurrentA3MenuClicked()}
                                        icon={<FaFile />}>
                                        A3
                                   </MenuItem>
                                    <MenuItem
                                        onClick={() => handleMenuClickedPrintPreview()}
                                        icon={<FaPrint />}>
                                        Print preview
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => handleMenuClickedPrintDSM()}
                                        icon={<FaPrint />}>
                                        Print preview DSM
                                    </MenuItem>
                                </Menu>
                                : null
                            }
                            {current && current.a3Id && isAuthenticated && (user.email === "athina.debaetselier@alvancegroup.com") ?
                                <Menu iconShape="circle">
                                    <MenuItem>Current</MenuItem>
                                    <MenuItem
                                        onClick={() => handleCurrentA3MenuClicked()}
                                        icon={<FaFile />}>
                                        A3
                                   </MenuItem>
                                    <MenuItem
                                        onClick={() => handleMenuClickedPrintPreview()}
                                        icon={<FaPrint />}>
                                        Print preview
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => handleMenuClickedPrintAlvance()}
                                        icon={<FaPrint />}>
                                        Print preview Alvance
                                    </MenuItem>
                                </Menu>
                                : null
                            }
                            {
                                isAuthenticated && user.email === "dev.mark.volders@thinka3.com" ?
                                    <Menu iconShape="circle">
                                        <MenuItem>Current (dev)</MenuItem>
                                        <MenuItem
                                            onClick={() => handleMenuClickedTodo()}
                                            icon={<FaListAlt />}>
                                            {intl.formatMessage({ id: 'todo' })}
                                        </MenuItem>
                                    </Menu>
                                    : null
                            }
                            {
                                isAuthenticated ?
                                    <Menu iconShape="circle">
                                        <MenuItem>Preferences</MenuItem>
                                        <MenuItem
                                            onClick={() => history.push("/settings")}
                                            icon={<FaGrinTongueWink />}>
                                            Settings
                                        </MenuItem>
                                    </Menu>
                                    : null
                            }
                            {
                                isAuthenticated && user.email === "mark.volders@thinka3.com" ?
                                    <Menu iconShape="circle">
                                        <MenuItem>Mock</MenuItem>
                                        <MenuItem
                                            onClick={() => history.push("/mocka3s")}
                                            icon={<FaGrinTongueWink />}>
                                            A3s
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => history.push("/mocka3")}
                                            icon={<FaGrinTongueWink />}>
                                            A3
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => history.push("/mockboxgrid")}
                                            icon={<FaGrinTongueWink />}>
                                            BoxGrid
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => history.push("/mockbox")}
                                            icon={<FaGrinTongueWink />}>
                                            Box
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => history.push("/mockboxheader")}
                                            icon={<FaGrinTongueWink />}>
                                            BoxHeader
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => history.push("/mockview")}
                                            icon={<FaGrinTongueWink />}>
                                            View
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => history.push("/mockpage")}
                                            icon={<FaGrinTongueWink />}>
                                            Page
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => history.push("/mockpagelayout")}
                                            icon={<FaGrinTongueWink />}>
                                            PageLayout
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => history.push("/fishbone")}
                                            icon={<FaGrinTongueWink />}>
                                            Fishbone
                                        </MenuItem>
                                    </Menu>
                                    : null
                            }
                        </SidebarContent>
                }

                <SidebarFooter style={{ textAlign: 'center' }}>
                    <div
                        className="sidebar-btn-wrapper"
                        style={{
                            padding: '20px 24px',
                        }}
                    >
                        Version 0.2.13 {current.db ? " - ".concat(current.db) : null}
                    </div>
                </SidebarFooter>
            </ProSidebar >
        </ComponentWrapper>
    );
};

export default Aside;
