import React, { useState } from 'react';
import 'react-dropzone-uploader/dist/styles.css'
import Layout from './Layout';
import localA3Template from "./data";
import hotkeys from 'hotkeys-js';
import Profile from './components/Profile';
import { Redirect, Route, Switch, useParams, useLocation, BrowserRouter, Link } from 'react-router-dom';
import 'react-pro-sidebar/dist/css/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Authorize from './Authorize';
import A3 from './A3';
import TodoApp from './components/TodoApp'
import Overview from './containers/Overview';
import FakeMediumApp from './containers/FakeMediumApp';
import MockPageLayout from './containers/MockPageLayout';
import MockBoxHeader from './containers/MockBoxHeader';
import MockBox from './containers/MockBox';
import MockBoxGrid from './containers/MockBoxGrid';
import MockBoxA3 from './containers/MockA3';
import MockBoxA3s from './containers/MockA3s';
import A3sV2 from './components/A3sV2';
import A3V2 from './components/A3V2';
import LoremIpsum from './components/LoremIpsum';
import Settings from './containers/Settings';
import PrintA3Alvance from './components/PrintA3Alvance';
import PrintA3Lean from './components/PrintA3Lean';
import PrintA3DSM from './components/PrintA3DSM';
import PrintA3TA3 from './components/PrintA3TA3';
import Fishbone from './components/Fishbone';


const App = (props) => {
	//console.log("App - init called")
	const { screenWidth, screenHeight } = props;

	const axios = require('axios');

	const useA3WithLocalStorage = localStorageKey => {
		//console.log("Retrieving value from localStorage")

		const [value, setValue] = React.useState(
			localStorage.getItem(localStorageKey)
		);

		React.useEffect(() => {

			axios.get(process.env.REACT_APP_API_URL.concat('/api/getImage'), {
				params: {
					uuid: localStorageKey
				}
			})
				.then(function (response) {
					localStorage.setItem(localStorageKey, response.data);
				})
				.catch(function (error) {
					console.log(error);
				})
				.then(function () {
					// always executed
				});

		}, [value]);

		return [value, setValue];
	};

	const [error, setError] = React.useState(null);
	const [isLoaded, setIsLoaded] = React.useState(false);

	const [a3template, setA3Template] = React.useState(JSON.stringify(localA3Template));
	React.useEffect(() => {
		fetch(process.env.REACT_APP_API_URL.concat("/api/gettemplate"))
			.then(res => res.json())
			.then(
				(result) => {
					//console.log("Result retrieved from api");
					//console.log(result);
					setIsLoaded(true);
					setA3Template(result);
				},
				// Note: it's important to handle errors here
				// instead of a catch() block so that we don't swallow
				// exceptions from actual bugs in components.
				(error) => {
					setIsLoaded(true);
					setError(error);
				}
			)
	}, [])

	const useStateWithLocalStorage = localStorageKey => {
		//console.log("Retrieving value from localStorage")

		const [value, setValue] = React.useState(
			localStorage.getItem(localStorageKey) || a3template
		);

		React.useEffect(() => {
			try {
				localStorage.setItem(localStorageKey, value);
			} catch (error) {
				console.log(error);

				//Clear the local storage and try once more
				localStorage.clear();

				try {
					localStorage.setItem(localStorageKey, value);
				} catch (error) {
					console.log(error);
				}
			}
		}, [value]);

		return [value, setValue];
	};

	const [data, setData] = useStateWithLocalStorage('data71');
	//console.log(JSON.parse(data));

	const [focusBox, setfocusBox] = React.useState(-1);

	function getBase64(file) {
		//console.log(file);
		var reader = new FileReader();
		reader.readAsText(file);
		reader.onload = function () {
			var result = reader.result;
			//console.log(result);
			setData(result);
		};
		reader.onerror = function (error) {
			console.log('Error: ', error);
		};
	}

	const handleFileFromPC = (file) => {
		getBase64(file)
	}

	function handleLayoutChange(param) {
		//console.log("Layout - handleLayoutChange called")

		var newJson = JSON.parse(param);
		setData(JSON.stringify(newJson));
	}

	function handleTitleChange(param) {
		//console.log("App - handleTitleChange called")

		var newJson = JSON.parse(data);
		newJson.title = param;
		setData(JSON.stringify(newJson));

		//console.log(newJson);
	}

	function handleSponsorChange(param) {
		var newJson = JSON.parse(data);
		newJson.sponsor = param;
		setData(JSON.stringify(newJson));
	}

	function handleProcessOwnerChange(param) {
		var newJson = JSON.parse(data);
		newJson.processOwner = param;
		setData(JSON.stringify(newJson));
	}

	function addCkEditorItemToCarrousel(i) {
		//console.log("App - addCkEditorItemToCarrousel called");
		//console.log(i);

		var newJson = JSON.parse(data);
		//console.log(newJson.box[i].content);
		newJson.box[i].content.push({ 'value': 'New box', 'type': 'editor' });
		setData(JSON.stringify(newJson));

	}

	const handleSaveToPC = jsonData => {
		//console.log("App - handleSaveToPC called")
		const fileData = JSON.stringify(jsonData);
		const blob = new Blob([fileData], { type: "text/plain" });
		const url = URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.download = JSON.parse(jsonData).title.concat(".json");
		link.href = url;
		link.click();
	}

	function handleFocusBoxChange(fb) {
		//console.log("App - handleFocusBoxChange called");

		focusBox !== fb ? setfocusBox(fb) : setfocusBox(-1);
	}

	hotkeys('ctrl+s, f1, f2, f3, f4, f5, f6, f7, f8, f9', function (event, handler) {
		// Prevent the default refresh event under WINDOWS system
		event.preventDefault()
		switch (handler.key) {
			case 'ctrl+s': handleSaveToPC(data);
				break;
			case 'f1': focusBox !== 0 ? setfocusBox(0) : setfocusBox(-1);
				break;
			case 'f2': focusBox !== 1 ? setfocusBox(1) : setfocusBox(-1);
				break;
			case 'f3': focusBox !== 2 ? setfocusBox(2) : setfocusBox(-1);
				break;
			case 'f4': focusBox !== 3 ? setfocusBox(3) : setfocusBox(-1);
				break;
			case 'f5': focusBox !== 4 ? setfocusBox(4) : setfocusBox(-1);
				break;
			case 'f6': focusBox !== 5 ? setfocusBox(5) : setfocusBox(-1);
				break;
			case 'f7': focusBox !== 6 ? setfocusBox(6) : setfocusBox(-1);
				break;
			case 'f8': focusBox !== 7 ? setfocusBox(7) : setfocusBox(-1);
				break;
			case 'f9': focusBox !== 8 ? setfocusBox(8) : setfocusBox(-1);
				break;
			default: ;

		}

	});

	return (

		<Switch>
			{/*
							//MV - To be further investigated how the default url can be set without breaking the login logic with Auth0
							<Route exact path="/">
								<Redirect to="/A3" />
							</Route>														
							*/}

			<Route path="/A3Overview" component={A3sV2} />
			<Route path="/authorize" component={Authorize} />
			<Route path="/todo" component={TodoApp} />
			<Route path="/profile" component={Profile} />
			<Route path="/fakemediumapp" component={FakeMediumApp} />
			<Route path="/mockpagelayout" component={MockPageLayout} />
			<Route path="/mockboxheader" component={MockBoxHeader} />
			<Route path="/mockbox" component={MockBox} />
			<Route path="/mockboxgrid" component={MockBoxGrid} />
			<Route path="/mocka3" component={MockBoxA3} />
			<Route path="/mocka3s" component={MockBoxA3s} />
			<Route path="/loremipsum" component={LoremIpsum} />
			<Route path="/settings" component={Settings} />
			<Route path="/fishbone" component={Fishbone} />

			<Route
				path="/a3"
				render={() => (
					<A3V2
						screenWidth={screenWidth}
						screenHeight={screenHeight}
					/>
				)}
			/>

			<Route
				path="/printA3Alvance"
				render={() => (
					<PrintA3Alvance
						screenWidth={screenWidth}
						screenHeight={screenHeight}
					/>
				)}
			/>

			<Route
				path="/printA3DSM"
				render={() => (
					<PrintA3DSM
						screenWidth={screenWidth}
						screenHeight={screenHeight}
					/>
				)}
			/>

			<Route
				path="/printA3"
				render={() => (
					<PrintA3TA3
						screenWidth={screenWidth}
						screenHeight={screenHeight}
					/>
				)}
			/>

			<Route
				path="/printA3Lean"
				render={() => (
					<PrintA3Lean
						screenWidth={screenWidth}
						screenHeight={screenHeight}
					/>
				)}
			/>

			<Route
				path="/A3/:a3id"
				render={({ match }) => (
					<div>
						<A3
							a3id={match.params.a3id}
						/>
					</div>
				)}
			/>
			<Route path="/" component={A3sV2} />

		</Switch>

	);
};

export default App;

/*
			<div id="dropzone">
				<Dropzone
					onChangeStatus={handleChangeStatus}
					maxFiles={1}
					multiple={false}
					canCancel={false}
					canRemove={false}
					inputContent="Open existing A3"
					styles={{ dropzone: { minHeight: 50, maxHeight: 70, maxWidth: 180, overflow: "hidden" } }}
				/>
			</div>
*/

/*
							<Route
								path="/"
								render={(props) => (
									<Layout data={JSON.parse(data)}
										focusBox={focusBox}
										onFocusBoxChange={handleFocusBoxChange}
										onLayoutChange={handleLayoutChange}
										onTitleChange={handleTitleChange}
										onSponsorChange={handleSponsorChange}
										onProcessOwnerChange={handleProcessOwnerChange}
										onAddCkEditorItemToCarrousel={addCkEditorItemToCarrousel}
										onFileFromPC={handleFileFromPC}
									/>)}
							/>
*/